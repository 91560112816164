import React, { useMemo, useRef, useState } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { CallActionTypes } from './CallActionsTypes'
import io from 'socket.io-client'
import { AuthService } from '../../services/AuthService'
import { AuthContext } from '../AuthReducer'
import useSound from 'use-sound'
import callSound from '../../assets/call.mp3'
import { INTERPRETER_STATE } from '../../constants/defaultConstants'

const initialState = {
    selectedCall: {
        sessionId: '',
        token: ''
    },
    incomingCalls: [],
    historyCalls: [],
    selectedHistoryCall: {},
    contacts: [],
    selectedContact: {},
    othersCalls: [],
    questioner: {
        status: '',
        userId: ''
    },
    operatorStatus: 'online',
    operators: []
}

const reducer = (state, action) => {
    switch (action.type) {
        case CallActionTypes.PREVIEW_CALL: {
            if (state.selectedCall?.sessionId?.length === 0) {
                return {
                    ...state,
                    selectedCall: action.payload
                }
            } else {
                return state
            }
        }
        case CallActionTypes.TAKE_CALL: {
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionId != state.selectedCall.sessionId
            )

            return {
                ...state,
                selectedCall: { ...state.selectedCall, status: 'answered' },
                incomingCalls: newIncoming
            }
        }
        case CallActionTypes.DELETE_CALL: {
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionId !== action.payload
            )

            return {
                ...state,
                incomingCalls: newIncoming,
                selectedCall:
                    state.selectedCall.sessionId === action.payload
                        ? initialState.selectedCall
                        : state.selectedCall
            }
        }
        case CallActionTypes.END_CALL: {
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionId !== state.selectedCall.sessionId
            )

            return {
                ...state,
                incomingCalls: newIncoming,
                selectedCall: initialState.selectedCall
            }
        }
        case CallActionTypes.SET_INCOMING_CALLS: {
            return {
                ...state,
                incomingCalls: [action.payload, ...state.incomingCalls]
            }
        }
        case CallActionTypes.SET_OTHERS_CALLS: {
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionId != action.payload
            )
            const newOthers = state.incomingCalls.find(
                (el) => el.sessionId === action.payload
            )

            return {
                ...state,
                othersCalls: newOthers
                    ? [
                          ...state.othersCalls,
                          { ...newOthers, status: 'reserved' }
                      ]
                    : state.othersCalls,
                incomingCalls: newIncoming,
                selectedCall:
                    state.selectedCall.sessionId === action.payload &&
                    state.selectedCall.status !== 'answered'
                        ? initialState.selectedCall
                        : state.selectedCall
            }
        }
        case CallActionTypes.GET_CONTACTS: {
            return {
                ...state,
                contacts: [...state.contacts, ...action.payload.items],
                nextPage:
                    action.payload.currentPage !== action.payload.totalPages
            }
        }
        case CallActionTypes.CLEAR_CONTACTS: {
            return {
                ...state,
                contacts: initialState.contacts,
                nextPage: null
            }
        }
        case CallActionTypes.GET_CALL_HISTORY: {
            return {
                ...state,
                historyCalls: [...state.historyCalls, ...action.payload.data],
                nextPage: action.payload.next_page
            }
        }
        case CallActionTypes.CLEAR_CALL_HISTORY: {
            return {
                ...state,
                historyCalls: []
            }
        }
        case CallActionTypes.SET_SELECTED_CONTACT: {
            return { ...state, selectedContact: { ...action.payload } }
        }
        case CallActionTypes.SET_QUESTIONER: {
            return { ...state, questioner: action.payload }
        }
        case CallActionTypes.REMOVE_QUESTIONER: {
            return { ...state, questioner: { userId: '', status: '' } }
        }
        case CallActionTypes.SET_SELECTED_HISTORY: {
            return { ...state, selectedHistoryCall: action.payload }
        }
        case CallActionTypes.SET_OPERATOR_STATUS: {
            return { ...state, operatorStatus: action.payload }
        }
        case 'ADD_NEW_OPERATOR': {
            let newOperators

            if (state.operators.find((el) => el.id === action.payload.id)) {
                newOperators = state.operators.map((el) =>
                    el.id === action.payload.id ? action.payload : el
                )
            } else {
                newOperators = [...state.operators, action.payload]
            }

            return { ...state, operators: newOperators }
        }
        case 'CHANGE_OPERATOR_STATUS': {
            return {
                ...state,
                operators: state.operators.map((el) =>
                    el.id === action.payload.userId
                        ? { ...el, activity: action.payload.status }
                        : el
                )
            }
        }
        default:
            return state
    }
}

export const CallContext = createContext(initialState)
export const useCallContext = () => useContext(CallContext)

export const CallProvider = ({ children }) => {
    const [callState, dispatchCall] = useReducer(reducer, initialState)
    const [publisher, setPublisher] = useState(null)
    const [publisherVideo, setPublisherVideo] = useState(true)
    const [publisherAudio, setPublisherAudio] = useState(true)
    const sessionRef = useRef(null)
    const [isVolumeOn, setIsVolumeOn] = useState(true)
    const [isCalling, setIsCalling] = useState(false)

    const [sessionData, setSessionData] = useState(null)
    const [bookingModalInfo, setBookingModalInfo] = useState(null)
    const [chatMessages, setChatMessages] = useState([])
    const [rightTabId, setRightTabId] = useState(2)

    const [canStartCall, setCanStartCall] = useState(false)
    const [streamAvailable, setStreamAvailable] = useState(false)
    const [subscribersToRender, setSubscribersToRender] = useState([])
    const [isOffSpeaker, setIsOffSpeaker] = useState(false)
    const [handUpCount, setHandUpCount] = useState(0)
    const flag = useRef(true)
    const [state, dispatch] = useContext(AuthContext)
    const [playSound, exposedData] = useSound(callSound, {
        interrupt: true,
        volume: 1,
        id: 1,
        loop: true
    })

    useEffect(() => {
        if (
            callState?.selectedCall?.sessionId?.length > 0 &&
            callState?.selectedCall?.status !== 'answered' &&
            isVolumeOn &&
            isCalling
        ) {
            playSound()
        } else {
            exposedData.stop()
        }
    }, [callState?.selectedCall, isVolumeOn, isCalling, playSound, exposedData])

    useEffect(() => {
        publisher?.publishVideo(publisherVideo)
    }, [publisherVideo])

    useEffect(() => {
        publisher?.publishAudio(publisherAudio)
    }, [publisherAudio])

    const callSocket = useMemo(() => {
        if (!state.authenticated()) return
        return io(`${process.env.REACT_APP_SOCKET_URL}/call`, {
            path: '/v2',
            extraHeaders: {
                'x-interpreter-app-type': 'connectoperator',
                authorization: `Bearer ${AuthService?.getUser()?.token}`
            }
        })
    }, [state.authenticated()])

    const operatorSocket = useMemo(() => {
        if (!state.authenticated()) return
        return io(`${process.env.REACT_APP_SOCKET_URL}/operator`, {
            path: '/v2',
            extraHeaders: {
                'x-interpreter-app-type': 'connectoperator',
                authorization: `Bearer ${AuthService?.getUser()?.token}`
            }
        })
    }, [state.authenticated()])

    useEffect(() => {
        if (!callSocket || !operatorSocket) return

        callSocket.on('connect', () => {
            console.log('call socket connected', callSocket.id)
        })

        operatorSocket.on('connect', (payload) => {
            console.log('operator socket connect', operatorSocket.id)
        })
        operatorSocket.on('new:call:created', (response) => {
            console.log('new call create', response)
            const { apiKey, call, user, deviceIsTablet } = response

            const newIncoming = {
                sessionId: call.sessionId,
                token: call.token,
                receiverSocketId: user.socketId,
                user: user,
                startedAt: call.initiatedAt,
                reason: call.type === 'laptopToGroup' ? 'group' : call.reason,
                groupSessionId: call.groupSessionId,
                lang: call.language,
                call: call,
                apiKey: apiKey,
                type: call.type,
                deviceIsTablet
            }
            dispatchCall({
                type: CallActionTypes.PREVIEW_CALL,
                payload: newIncoming
            })
            dispatchCall({
                type: CallActionTypes.SET_INCOMING_CALLS,
                payload: newIncoming
            })
            setIsCalling(true)
        })

        operatorSocket.on('call:redirected', (response) => {
            console.log('call:redirected', response)
            const { apiKey, call, user } = response

            const newIncoming = {
                sessionId: call.sessionId,
                token: call.token,
                receiverSocketId: user.socketId,
                user: user,
                startedAt: call.initiatedAt,
                reason: call.type === 'laptopToGroup' ? 'group' : call.reason,
                groupSessionId: call.groupSessionId, // add if
                lang: call.language,
                call: call,
                apiKey: apiKey,
                type: call.type
            }

            dispatchCall({
                type: CallActionTypes.PREVIEW_CALL,
                payload: newIncoming
            })
            dispatchCall({
                type: CallActionTypes.SET_INCOMING_CALLS,
                payload: newIncoming
            })
            setIsCalling(true)
        })

        operatorSocket.on('operator:activities', (payload) => {
            console.log('operator activities ', payload)
        })
        operatorSocket.on('interpreter:activity:changed', (payload) => {
            console.log('operatorSocket1 activity ', payload)
            dispatchCall({ type: 'CHANGE_OPERATOR_STATUS', payload })
        })

        operatorSocket.on('call:booked', (payload) => {
            console.log('booking', payload)
            if (payload.status !== 'created') return
            setBookingModalInfo(payload)
        })
        operatorSocket.on('call:reserved', (response) => {
            console.log('call reserved', response)

            const replier = response?.listeners?.find(
                (el) => el.type === 'replier'
            )

            if (response?.call?.status === 'ended') {
                dispatchCall({
                    type: CallActionTypes.DELETE_CALL,
                    payload: response.call.sessionId
                })
            }

            if (AuthService?.getUser().email !== replier?.properties?.name) {
                dispatchCall({
                    type: CallActionTypes.DELETE_CALL,
                    payload: response.sessionId
                })
            }
        })

        operatorSocket.on('interpreters:statuses', (response) => {
            console.log('statuses', response)
            response.forEach((el) => {
                dispatchCall({ type: 'ADD_NEW_OPERATOR', payload: el })
            })
        })

        callSocket.on('disconnect', () => {
            console.log('call socket disconnect')
        })

        operatorSocket.on('disconnect', () => {
            console.log('operator socket disconnect')
        })

        callSocket.on('exception', (payload) => {
            console.log('CALL SOCKET ERROR: ', payload)

            if (payload.code === 401) {
                localStorage.removeItem(`${process.env.REACT_ENV_TYPE}_user`)
                window.location.reload()
            }
        })

        operatorSocket.on('exception', (payload) => {
            console.log('OPERATOR SOCKET ERROR: ', payload)

            if (payload.code === 401) {
                localStorage.removeItem(`${process.env.REACT_ENV_TYPE}_user`)
                window.location.reload()
            }
        })

        return () => {
            callSocket.disconnect()
            operatorSocket.disconnect()
        }
    }, [])

    const switchOperator = async (socketId) => {
        await sessionRef.current.sessionHelper.session.signal({
            data: '',
            type: 'operatorCanRedirect'
        })

        const redirected = {
            type: 'redirected',
            sessionId: sessionRef?.current?.props?.sessionId,
            disconnectedId: AuthService?.getUser()?.id,
            socketId,
            connectionId: publisher?.session?.connection?.connectionId
        }

        console.log(redirected)
        callSocket.emit('call:handle:disconnect', redirected, (res) => {
            console.log('disconnected', res)
            dispatchCall({
                type: CallActionTypes.END_CALL
            })
        })
    }

    const reserveCall = () => {
        const { apiKey, call, type, user } = callState.selectedCall

        const reservationObj = { apiKey, call, type, user }
        callSocket.emit('call:reservation', reservationObj, (payload) => {
            console.log('reservation ', payload)

            setIsCalling(false)
            dispatchCall({ type: CallActionTypes.TAKE_CALL })
        })
    }

    const activityChange = (activity) => {
        operatorSocket.emit('activity:status', activity, (payload) => {
            console.log('activity change', payload)
            dispatchCall({
                type: CallActionTypes.SET_OPERATOR_STATUS,
                payload: payload.status
            })
        })
    }

    const endCall = () => {
        setCanStartCall(false)
        setStreamAvailable(false)
        setSubscribersToRender([])
        setIsOffSpeaker(false)
        setHandUpCount(0)

        dispatchCall({
            type: CallActionTypes.END_CALL
        })

        flag.current = false
    }

    const handleAccept = () => {
        reserveCall()
        setCanStartCall(true)

        setTimeout(() => {
            if (!flag.current) {
                endCall()
            }
        }, 30000)
    }

    return (
        <CallContext.Provider
            value={{
                callState,
                dispatchCall,
                publisher,
                setPublisher,
                publisherVideo,
                setPublisherVideo,
                publisherAudio,
                setPublisherAudio,
                reserveCall,
                sessionRef,
                isVolumeOn,
                setIsVolumeOn,
                sessionData,
                bookingModalInfo,
                setBookingModalInfo,
                activityChange,
                switchOperator,
                chatMessages,
                setChatMessages,
                rightTabId,
                setRightTabId,
                canStartCall,
                setCanStartCall,
                streamAvailable,
                setStreamAvailable,
                subscribersToRender,
                setSubscribersToRender,
                isOffSpeaker,
                setIsOffSpeaker,
                handUpCount,
                setHandUpCount,
                handleAccept,
                endCall,
                flag
            }}
        >
            {children}
        </CallContext.Provider>
    )
}
