import React, { useEffect, useState } from 'react'
import { OTSession, OTSubscriber } from 'opentok-react'
import Lottie from 'react-lottie'
import { Box } from '@material-ui/core'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import Logo from '../../Logo'
import Loader from '../../assets/lottie/loader.json'
import { fullHeight } from '../../utils/constants'
import CamOnIcon from '../../assets/camOnIcon.svg'
import CamOffIcon from '../../assets/camOffIcon.svg'
import AudioOnIcon from '../../assets/sound-off-icon.svg'
import AudioOffIcon from '../../assets/volumeIconOff.svg'
import DeclineBtn from '../../assets/declineButton.svg'
import NoUserIcon from '../../assets/no-user-icon.svg'
import HandUpIcon from '../../assets/hand-up-icon.svg'

import { CallButtons } from './CallButtons'
import { CallPreview } from './CallPreview'

export const CallPage = () => {
    const [audioIsSound, setAudioIsSound] = useState(true)

    const {
        callState,
        dispatchCall,
        publisher,
        reserveCall,
        sessionRef,
        setChatMessages,
        setRightTabId,
        canStartCall,
        setCanStartCall,
        streamAvailable,
        setStreamAvailable,
        subscribersToRender,
        setSubscribersToRender,
        isOffSpeaker,
        setIsOffSpeaker,
        handUpCount,
        setHandUpCount,
        handleAccept,
        endCall,
        flag
    } = useCallContext()

    const { selectedCall } = callState
    const { sessionId, token, startedAt, deviceIsTablet } = selectedCall

    const isGroupCall = selectedCall.reason === 'group'

    const filteredSubscribers = subscribersToRender.filter(
        (sub) => sub.isSelectedUser
    )

    const sessionEventHandlers = {
        streamCreated: (event) => {
            console.log('Stream Created', event)
            const { stream } = event

            const newUser = {
                stream,
                isCameraOn: stream.hasVideo,
                isAudioOn: stream.hasAudio,
                name: stream.name,
                isShowEndBtn:
                    (isGroupCall && stream.name.includes('amsaanpro')) ||
                    !isGroupCall,
                isAmsaanPro: stream.name.includes('amsaanpro'),
                isSelectedUser:
                    !isGroupCall ||
                    (isGroupCall && stream.name.includes('amsaanpro'))
            }

            setSubscribersToRender((prevState) => [...prevState, newUser])

            setStreamAvailable(true)
            flag.current = true
        },
        streamDestroyed: (event) => {
            console.log('Stream Destroyed', event)
            const userName = event.stream.name
            const isGroupCall = selectedCall.reason === 'group'

            setSubscribersToRender((prevState) =>
                prevState.filter((el) => el.stream.id !== event.stream.id)
            )

            if (userName.includes('amsaanpro') || !isGroupCall)
                dispatchCall({
                    type: CallActionTypes.END_CALL
                })
        },
        streamPropertyChanged: (event) => {}
    }

    useEffect(() => {
        if (!callState.selectedCall.sessionId?.length) {
            endCall()
        }
    }, [callState.selectedCall])

    useEffect(() => {
        sessionRef?.current?.sessionHelper.session.on('signal', (event) => {
            console.log('SIGNAL', event)

            const eventType = event.type.split(':')[1]

            if (eventType === 'chatMessage') {
                const from =
                    sessionRef.current.sessionHelper.session.connection
                        .connectionId === event.from.connectionId
                        ? 'intrepreter'
                        : 'client'

                setRightTabId(1)

                const name = event.target.streams.find(
                    (el) =>
                        el.connection.connectionId === event.from.connectionId
                )?.name

                setChatMessages((prevState) => {
                    const messageItem = {
                        from,
                        name: name ?? '',
                        data: event.data,
                        id: Math.random() * 100,
                        // id: prevState.length,
                        sendedAt: new Date()
                    }

                    return prevState
                        ? [messageItem, ...prevState]
                        : [messageItem]
                })
            }
            if (eventType === 'blockMicro') {
                setSubscribersToRender((prevState) => {
                    return prevState.map((sub) =>
                        sub.stream.id === event.data
                            ? {
                                  ...sub,
                                  isAudioOn: false
                              }
                            : sub
                    )
                })
            }
            if (eventType === 'unblockMicro') {
                setSubscribersToRender((prevState) => {
                    return prevState.map((sub) =>
                        sub.stream.id === event.data
                            ? {
                                  ...sub,
                                  isAudioOn: true
                              }
                            : sub
                    )
                })
            }
            if (eventType === 'blockCamera') {
                setSubscribersToRender((prevState) => {
                    return prevState.map((sub) => {
                        if (sub.stream.id === event.data) {
                            if (sub.isAmsaanPro) {
                                setIsOffSpeaker(isGroupCall && sub.isAmsaanPro)
                            }
                        }

                        return sub.stream.id === event.data
                            ? {
                                  ...sub,
                                  isCameraOn: false
                              }
                            : sub
                    })
                })
            }
            if (eventType === 'unblockCamera') {
                setSubscribersToRender((prevState) => {
                    return prevState.map((sub) => {
                        if (sub.stream.id === event.data) {
                            if (sub.isAmsaanPro) {
                                setIsOffSpeaker(false)
                            }
                        }

                        return sub.stream.id === event.data
                            ? {
                                  ...sub,
                                  isCameraOn: true
                              }
                            : sub
                    })
                })
            }
            if (eventType === 'handUp') {
                setHandUpCount((prev) => prev + 1)
            }
            if (eventType === 'unHandUp') {
                setHandUpCount((prev) => prev - 1)
            }
            if (eventType === 'setSelectedUser') {
                setSubscribersToRender((prevState) => {
                    const activeClientId = prevState.findIndex(
                        (el) => el.isSelectedUser && !el.isAmsaanPro
                    )

                    if (activeClientId >= 0) {
                        sessionRef.current.sessionHelper.session.signal({
                            data: prevState[activeClientId].stream.id,
                            type: 'removeSelectedUser'
                        })
                    }

                    return prevState.map((sub) =>
                        sub.stream.id === event.data
                            ? {
                                  ...sub,
                                  isSelectedUser: true,
                                  isAudioOn: false,
                                  isCameraOn: true
                              }
                            : sub
                    )
                })
            }
            if (eventType === 'removeSelectedUser') {
                setSubscribersToRender((prevState) => {
                    return prevState.map((sub) =>
                        sub.stream.id === event.data
                            ? {
                                  ...sub,
                                  isSelectedUser: false,
                                  isAudioOn: false,
                                  isCameraOn: true
                              }
                            : sub
                    )
                })

                setHandUpCount((prev) => prev - 1)
            }
        })
    }, [selectedCall.status === 'answered'])

    return (
        <Box
            width="100%"
            style={{
                verticalAlign: 'center',
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                display: 'flex'
            }}
        >
            {!!selectedCall.sessionId.length && !canStartCall ? (
                <CallPreview
                    setCanStartCall={setCanStartCall}
                    reserveCall={reserveCall}
                    startedAt={new Date(startedAt)}
                    handleAccept={handleAccept}
                />
            ) : !!sessionId.length ? (
                <Box width="100%" height={fullHeight} style={{ width: '100%' }}>
                    <OTSession
                        width={'100%'}
                        ref={sessionRef}
                        eventHandlers={sessionEventHandlers}
                        onConnect={() => {
                            canStartCall &&
                                sessionRef?.current?.sessionHelper.session.publish(
                                    publisher
                                )
                        }}
                        apiKey={process.env.REACT_APP_OPENTOK_API_KEY}
                        sessionId={sessionId}
                        token={token}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                height: fullHeight,
                                width: '100%'
                            }}
                        >
                            {filteredSubscribers.map((subscriber) => {
                                const isTwoSubscribers =
                                    filteredSubscribers.length === 2

                                const userAudioHandler = () => {
                                    setSubscribersToRender((prevState) => {
                                        return prevState.map((el) =>
                                            el.stream.id ===
                                            subscriber.stream.id
                                                ? {
                                                      ...el,
                                                      isAudioOn:
                                                          !subscriber.isAudioOn
                                                  }
                                                : el
                                        )
                                    })

                                    sessionRef.current.sessionHelper.session.signal(
                                        {
                                            data: subscriber.stream.id,
                                            type: subscriber.isAudioOn
                                                ? 'blockMicro'
                                                : 'unblockMicro'
                                        }
                                    )
                                }

                                const userVideoHandler = () => {
                                    setSubscribersToRender((prevState) => {
                                        return prevState.map((el) =>
                                            el.stream.id ===
                                            subscriber.stream.id
                                                ? {
                                                      ...el,
                                                      isCameraOn:
                                                          !subscriber.isCameraOn
                                                  }
                                                : el
                                        )
                                    })

                                    sessionRef.current.sessionHelper.session.signal(
                                        {
                                            data: subscriber.stream.id,
                                            type: subscriber.isCameraOn
                                                ? 'blockCamera'
                                                : 'unblockCamera'
                                        }
                                    )

                                    if (
                                        subscriber.isCameraOn &&
                                        !subscriber.isAmsaanPro &&
                                        isGroupCall
                                    ) {
                                        sessionRef.current.sessionHelper.session.signal(
                                            {
                                                data: subscriber.stream.id,
                                                type: 'removeSelectedUser'
                                            }
                                        )
                                    }

                                    setIsOffSpeaker(
                                        subscriber.isCameraOn &&
                                            isGroupCall &&
                                            subscriber.isAmsaanPro
                                    )
                                }

                                const isSmallWindow =
                                    subscriber.isAmsaanPro && isOffSpeaker
                                const isLargeWindow =
                                    !subscriber.isAmsaanPro && isOffSpeaker

                                const windowSize = !isTwoSubscribers
                                    ? '100%'
                                    : isSmallWindow
                                    ? '25%'
                                    : isLargeWindow
                                    ? '75%'
                                    : '50%'

                                return (
                                    <div
                                        style={{
                                            width: windowSize,
                                            height: fullHeight,
                                            position: 'relative',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            backgroundColor: '#000'
                                        }}
                                        key={subscriber.stream.id}
                                    >
                                        {subscriber.isCameraOn ? (
                                            <OTSubscriber
                                                key={subscriber.stream.id}
                                                stream={subscriber.stream}
                                                session={
                                                    sessionRef?.current
                                                        ?.sessionHelper?.session
                                                }
                                                style={{
                                                    maxWidth: deviceIsTablet
                                                        ? '100%'
                                                        : 600,
                                                    width: '100%',
                                                    height: fullHeight
                                                }}
                                                properties={{
                                                    width: '100%',
                                                    height: fullHeight
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    maxWidth: 600,
                                                    height: fullHeight,
                                                    backgroundColor: '#000',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <img
                                                    src={NoUserIcon}
                                                    alt="no user"
                                                    style={{
                                                        width: '20%',
                                                        height: '20%',
                                                        minHeight: 75,
                                                        minWidth: 75
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: 15,
                                                left: 0,
                                                zIndex: 100,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {subscriber.isAmsaanPro &&
                                                isGroupCall && (
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                'transparent',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            margin: '0 10px',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    '#F44336',
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: 12,
                                                                position:
                                                                    'absolute',
                                                                top: 0,
                                                                right: -3,
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center'
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    fontSize: 11,
                                                                    color: '#fff'
                                                                }}
                                                            >
                                                                {handUpCount}
                                                            </p>
                                                        </div>
                                                        <img
                                                            src={HandUpIcon}
                                                            alt="Hand"
                                                        />
                                                    </button>
                                                )}
                                            <button
                                                onClick={userAudioHandler}
                                                style={{
                                                    backgroundColor:
                                                        'transparent',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    margin: '0 10px'
                                                }}
                                            >
                                                <img
                                                    src={
                                                        subscriber.isAudioOn
                                                            ? AudioOffIcon
                                                            : AudioOnIcon
                                                    }
                                                    alt="Audio"
                                                />
                                            </button>
                                            {isGroupCall && (
                                                <button
                                                    onClick={userVideoHandler}
                                                    style={{
                                                        backgroundColor:
                                                            'transparent',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        margin: '0 10px'
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            subscriber.isCameraOn
                                                                ? CamOnIcon
                                                                : CamOffIcon
                                                        }
                                                        alt="Audio"
                                                    />
                                                </button>
                                            )}

                                            {subscriber.isShowEndBtn && (
                                                <button
                                                    onClick={() => {
                                                        dispatchCall({
                                                            type: CallActionTypes.END_CALL
                                                        })
                                                    }}
                                                    style={{
                                                        backgroundColor:
                                                            'transparent',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        margin: '0 10px'
                                                    }}
                                                >
                                                    <img
                                                        src={DeclineBtn}
                                                        alt="Decline"
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </OTSession>
                    {streamAvailable ? (
                        <CallButtons
                            callReason={selectedCall.reason}
                            setCanStartCall={endCall}
                            profile={selectedCall?.user?.profile}
                            setAudioIsSound={setAudioIsSound}
                            audioIsSound={audioIsSound}
                        />
                    ) : (
                        <Box
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                top: 'calc(-100vh + 40px)'
                            }}
                        >
                            <Lottie
                                options={{
                                    animationData: Loader,
                                    loop: true,
                                    autoplay: true
                                }}
                            />
                        </Box>
                    )}
                </Box>
            ) : (
                <Logo />
            )}
        </Box>
    )
}
